import { Heading, Spacer, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { getSEOLinks } from '../../../ui-page-modules/landing/landing.utils'
import { getPath } from '../../../routing/get-path'
import { BRAND_CONFIG } from '../../../config/brand-configurations'
import { Link } from '../../../vendor/next'
import { getSearchQueryString } from '../../../ui-page-modules/find-home/filters/utils/search.utils'

const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.core.softPink,
  marginBottom: -48,
  padding: '64px 24px 112px 24px',
  [theme.mediaQueries.mdUp]: {
    padding: theme.spacing['16x'],
    paddingBottom: 112,
  },
}))

const LinkWrapper = styled(Stack)(({ theme }) => ({
  rowGap: theme.spacing['12x'],
  [theme.mediaQueries.mdUp]: {
    gap: theme.spacing['8x'],
  },
}))

const LinkColumn = styled(Stack)(({ theme }) => ({
  width: '50%',
  paddingRight: theme.spacing['4x'],
  [theme.mediaQueries.mdUp]: {
    gap: theme.spacing['3x'],
    width: 'auto',
    flex: 1,
  },
}))

export function ListOfSEOLinks() {
  const { t } = useTranslation('list_of_seo_links')
  const sitemapLinks = getSEOLinks({ t })
  const countryCode = BRAND_CONFIG.countryCode
  return (
    <Wrapper>
      <Heading as="p" size="2xs">
        {t(`title`, { context: countryCode })}
      </Heading>
      <Spacer size="12x" />
      <LinkWrapper direction={'row'} wrap={'wrap'}>
        {sitemapLinks.map((column, index) => {
          return (
            <LinkColumn key={index}>
              {column.map(({ name, sharedHome, searchArea }, index) => {
                let link = getPath('findHome')
                if (sharedHome) {
                  link += getSearchQueryString({
                    filterValues: {
                      sharedHome: ['sharedHome'],
                      searchAreas: [searchArea],
                    },
                  })
                } else {
                  link += getSearchQueryString({
                    filterValues: {
                      homeTypes: ['apartment'],
                      searchAreas: [searchArea],
                    },
                  })
                }
                const text = sharedHome ? t('room', { name }) : t('apartment', { name })
                return (
                  <Link href={link} key={index}>
                    {text}
                  </Link>
                )
              })}
            </LinkColumn>
          )
        })}
      </LinkWrapper>
    </Wrapper>
  )
}
